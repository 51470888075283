import React from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Card, CardContent, CardHeader,
  Divider,
  List, ListItem, ListItemText,
  Tooltip, withStyles, Zoom,
} from '@material-ui/core';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import moment from 'moment';

import Typography from '../Typography';

import paleta from '../../configuraciones/paleta';
import { PLANTAS, TRAMPAS } from '../../constantes/tipoDeteccion';
import styles from './styles';

const paletaSemaforo = {
  "Verde": paleta.configuraciones.verde,
  "Amarillo": paleta.configuraciones.amarillo,
  "Rojo": paleta.configuraciones.rojo,
  "Indefinido": paleta.configuraciones.gris,
  "Vegetativo": paleta.configuraciones.vegetativo,
  "Balanceado": paleta.configuraciones.balanceado,
  "Generativo": paleta.configuraciones.generativo,
};

const CustomTooltip = withStyles(() => ({
  arrow: { 
    color: '#FFF',
  },
  tooltip: {
    backgroundColor: '#FFF',
    borderRadius: 6,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
    color: '#000',
    minWidth: 200,
    maxWidth: 450,
    padding: 0,
  },
}))(Tooltip);

const LabelMapa = ({
  texto,
  extraInfo,
  estado,
  parametro,
  onClick,
  mostrarTooltip,
  tooltipTitle,
  detalles,
  tipoDeteccion,
}) => {
  const classes = styles();
  const [mostrarAnotaciones, setMostrarAnotaciones] = React.useState(false);

  const onClickVerAnotaciones = React.useCallback(() => {
    setMostrarAnotaciones((prev) => !prev);
  }, []);

  const InformacionAMostrar = React.useMemo(() => {
    switch (tipoDeteccion) {
      case TRAMPAS:
        return (
          <>
            <Typography variant="h5" aling="center" style={{ paddingBlock: 10 }}>
              <MathJax inline>{`\\(μ_x = {\\sum x \\over n} =\\) `}</MathJax>
              <Typography variant="h5" component="span" style={{ color: paletaSemaforo[estado] }}>{parametro}</Typography>
            </Typography>
            {
              mostrarAnotaciones
              ?
                <List key="Anotaciones" dense className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(μ_x = Promedio_{(parámetro)}\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(\\sum x = Suma\\ de\\ parámetros\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(x = {(Hallazgos\\ / Trampas\\ monitoreadas\\ / Días\\ transcurridos)}\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(n = Días\\ capturados\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                </List>
              :
                <List key="Resultados" dense className={classes.list}>
                  {
                    detalles.map(({
                      cantidadDetecciones, diasTranscurridos, fecha,
                      parametro = '???', trampasMonitoreadas = 0,
                    }) => (
                      <ListItem className={classes.listItem} key={fecha}>
                        <ListItemText>
                          <Typography className={classes.formula}>
                            <MathJax>{`\\(x = {${cantidadDetecciones}\\ / ${trampasMonitoreadas}\\ / ${diasTranscurridos}}=${parametro}\\)`}</MathJax>
                          </Typography>
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          <Typography variant="subtitle2" className={classes.resultadoFecha}>
                            { moment(fecha).format('MMMM DD, YYYY') }
                          </Typography>
                          <Divider />
                        </ListItemText>
                      </ListItem>
                    ))
                  }
                </List>
            }
          </>
        );
      case PLANTAS:
        return (
          <>
            <Typography variant="h5" aling="center" style={{ paddingBlock: 10 }}>
              <MathJax inline>{`\\(x = {\\sum D \\ / PM \\ / n} =\\) `}</MathJax>
              <Typography variant="h5" component="span" style={{ color: paletaSemaforo[estado] }}>{parametro}</Typography>
            </Typography>
            {
              mostrarAnotaciones
              ?
                <List key="Anotaciones" dense className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(x = Parámetro\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(D = Detecciones\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(PM = Plantas\\ monitoreadas\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <MathJax>{"\\(n = Días\\ monitoreados\\)"}</MathJax>
                    </ListItemText>
                  </ListItem>
                </List>
              :
                <List key="Resultados" dense className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <ListItemText>
                      <Typography className={classes.formula}>
                        <MathJax>{`\\(x = {${detalles.reduce((suma, { cantidadDetecciones }) => suma + (+cantidadDetecciones), 0)} \\ / ${detalles.reduce((suma, { puntosMonitoreados }) => suma + (+puntosMonitoreados), 0)} \\ / ${detalles.length}} = {${parametro}}\\)`}</MathJax>
                      </Typography>
                      <Divider />
                    </ListItemText>
                  </ListItem>
                  {
                    detalles.map(({
                      cantidadDetecciones, fecha,
                    }) => (
                      <ListItem className={classes.listItem} key={fecha}>
                        <ListItemText>
                          <Typography className={classes.formula}>
                            <MathJax>{`\\(D = {${cantidadDetecciones}}\\)`}</MathJax>
                          </Typography>
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          <Typography variant="subtitle2" className={classes.resultadoFecha}>
                            { moment(fecha).format('MMMM DD, YYYY') }
                          </Typography>
                          <Divider />
                        </ListItemText>
                      </ListItem>
                    ))
                  }
                </List>
            }
          </>
        );
      default:
        return (
          detalles.map(({ parametro, valor }) => ((
            <Typography key={parametro} variant="subtitle2">
              { parametro }: <strong>{ valor }</strong>
            </Typography>
          )))
        );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoDeteccion, parametro, mostrarAnotaciones, detalles, estado]);

  return (
    <CustomTooltip
      leaveDelay={500}
      interactive
      title={
        <MathJaxContext>
          <Card sx={{ maxWidth: 345 }} className={classes.cardContainer}>
            <CardHeader
              style={{ padding: 0, margin: 0 }}
              title={
                <Typography variant="h6" component="div" className={classes.cardTitle} bold>
                  { tooltipTitle }
                </Typography>  
              }
              subheader={
                <Typography variant="h6" component="div" className={classes.cardSubtitle}>
                  {
                    detalles[detalles.length - 1]?.fecha
                      ? moment(detalles[detalles.length - 1]?.fecha).format('MMMM DD, YYYY')
                      : null
                  }
                </Typography>  
              }
              action={
                tipoDeteccion ? (
                  <Button onClick={onClickVerAnotaciones}>
                    { mostrarAnotaciones ? 'Ocultar' : 'Ver' } anotaciones
                  </Button>
                ) : null
              }
            />
            <CardContent style={{ padding: 0 }}>
              { InformacionAMostrar }
            </CardContent>
          </Card>
        </MathJaxContext>
      }
      TransitionComponent={Zoom}
      disableHoverListener={!mostrarTooltip}
      arrow
    >
    <div
      className={classes.root}
      style={{
        backgroundColor: paletaSemaforo[estado] || paleta.bar.primary,
      }}
      onClick={onClick}
    >
      <Typography className={classes.text}>{texto}</Typography>
      <div style={{ textAlign: 'center' }}>
        { extraInfo && <Typography bold className={classes.extraText}>{extraInfo}</Typography> }
      </div>
    </div>
    </CustomTooltip>
  )
}

LabelMapa.propTypes = {
  /** Texto del Marcador */
  texto: propTypes.string.isRequired,
  /** Informacion extra */
  extraInfo: propTypes.string,
  /** Estado del semáforo */
  estado: propTypes.string,
  /** Función que se ejecuta al hacer click en el marcador */
  onClick: propTypes.func,
  /** Indica si se debe mostrar el tooltip */
  mostrarTooltip: propTypes.bool,
  /** Titulo del tooltip */
  tooltipTitle: propTypes.string,
  /** Detalles del tooltip */
  detalles: propTypes.array,
};

LabelMapa.defaultProps = {
  estado: undefined,
  onClick: () => {},
  extraInfo: '',
  tooltipTitle: '',
  detalles: [],
  tipoDeteccion: null,
};

export default React.memo(LabelMapa);