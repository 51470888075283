export const APP_NAME = 'HARVEST VIEW';

const configuraciones = {
  URL_API: 'https://scouter-back.gamansolutions.mx/api/v1', // Desarrollo
  // URL_API: 'http://localhost:3001/api/v1', // Desarrollo
  ELEMENTOS_POR_PAGINA: 11,
  MAPS_API_KEY: 'AIzaSyAcR_MZyuhisGMYwU0SoJWBJyVH5dEK1W4',
  URL_MAPS: 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAcR_MZyuhisGMYwU0SoJWBJyVH5dEK1W4',
  OPEN_WEATHER_KEY: '4bd8579370e9208f34002d61ead323eb',
  prioridadesFenologias: {
    Generativo: 3,
    Balanceado: 2,
    Vegetativo: 1,
  },
};

export default configuraciones;
